<app-sidebar>
  <div class="content-wrapper fade-in">
    <div class="row text-center mb-0">
      <div class="col-md-12 grid-margin">
        <!-- <div class="row"> -->
        <!-- <div class="col-12 col-xl-8 mb-xl-0"> -->
        <h3 class="font-weight-bold">Welcome Admin</h3>
        <!-- </div> -->
        <!-- </div> -->
      </div>
    </div>
    <div class="row mt-0">
      <!-- <div class="col-md-6 grid-margin stretch-card">
        <div class="card tale-bg">
          <div class="card-people p-0">
            <img src="./assets/images/NewGradVisor Dashboard Image.png" alt="GradVisor dashboard">
            
          </div>
        </div>
      </div> -->

      <div class="col-md-4 mb-4 stretch-card transparent">
        <div class="card info-card card-tale">
          <div class="card-body">
            <p class="mb-3">Total Insights</p>
            <p class="fs-30" id="total-insights">{{TotalInsights}}</p>
            <p>_______________</p>
          </div>
        </div>
      </div>

      <div class="col-md-4 mb-4 mb-lg-0 stretch-card transparent">
        <div class="card info-card card-tale">
          <div class="card-body">
            <p class="mb-3">Total Seekers</p>
            <p class="fs-30" id="total-seekers">{{totalSeeker}}</p>
            <p>_______________</p>
          </div>
        </div>
      </div>

      <div class="col-md-4 stretch-card transparent">
        <div class="card info-card card-tale">
          <div class="card-body">
            <p class="mb-3">Total Sharers</p>
            <p class="fs-30" id="total-sharers">{{totalSharer}}</p>
            <p>_____________</p>
          </div>
        </div>
      </div>

    </div>

    <!-- <div class="row"> 
      <div class="col-lg-12">
        <div class="card shadow-sm d-flex justify-content-center chart-card py-3">
          <h4>Newly Registered Users</h4>
          <div class="filter-options py-2">
            <div class="btn-group">
           
              <input type="radio" checked id="daily" name="timeframe" value="daily"
                (change)="onTimeframeChange($event,1)">
              <label for="daily" class="btn">Daily</label>

              <input type="radio" id="week" name="timeframe" value="weekly" (change)="onTimeframeChange($event,1)">
              <label for="week" class="btn">Weekly</label>

              <input type="radio" id="month" name="timeframe" value="monthly" (change)="onTimeframeChange($event,1)">
              <label for="month" class="btn">Monthly</label>
            </div>
          </div>

          <p-chart class="canvas mt-4" type="line" height="300px" [data]="basicData" [options]="basicOptions"></p-chart>
        </div>
      </div>
    </div> -->

    <div class="row mt-4"> <!--Newly Registered Users Graph-->
      <div class="col-lg-12">
        <div class="card shadow-sm chart-card py-3">

          <div class="row chart-row">
            <div class="col-lg-12">
                <h4 class="text-center mt-2">Newly Registered Users</h4>

              <div class="filter-options user-option-row  px-3">
      
                <div class="btn-group insight-btn-group">
                  <input type="radio" checked id="daily" name="timeframe" value="daily"
                    (change)="onTimeframeChange($event)">
                  <label for="daily" class="btn">Daily</label>

                  <input type="radio" id="week" name="timeframe" value="weekly" (change)="onTimeframeChange($event)">
                  <label for="week" class="btn">Weekly</label>

                  <input type="radio" id="month" name="timeframe" value="monthly"
                    (change)="onTimeframeChange($event)">
                  <label for="month" class="btn">Monthly</label>
                </div>
              </div>

              <p-chart class="canvas mt-4" type="line" height="300px" [data]="basicData"
                [options]="basicOptions"></p-chart>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="row mt-4"> 
      <div class="col-lg-12">
        <div class="card shadow-sm chart-card py-3">
          <div class="row chart-row">
            <div class="col-lg-12">
              <h4 class="text-center mt-2">Insights</h4>
              <div class="filter-options py-2 px-3 mb-3">
                <div class="insight-sector-options mx-2 pl-3 text-center">
                  <label for="sectorDropdown">Select Sector :</label>
                  <select class="form-select option-size dropdown" id="sectorDropdown" [(ngModel)]="selectedSector" (change)="onSectorChange($event)">
                    <option  disabled selected>Please select a sector</option>
                    <option  value="All">All</option>
                    <option  *ngFor="let sector of SectorData"  [value]="sector.IN_id">{{sector.IN_name}}</option>
                  </select>
                </div>
                <div class="btn-group insight-btn-group">
                  <input type="radio" checked id="daily_insight" name="timeframe_insight" value="daily" (change)="onInsightTimeframeChange($event)">
                  <label for="daily_insight" class="btn">Daily</label>
    
                  <input type="radio" id="week_insight" name="timeframe_insight" value="weekly" (change)="onInsightTimeframeChange($event)">
                  <label for="week_insight" class="btn">Weekly</label>
    
                  <input type="radio" id="month_insight" name="timeframe_insight" value="monthly" (change)="onInsightTimeframeChange($event)">
                  <label for="month_insight" class="btn">Monthly</label>
                </div>
              </div>
              <p-chart class="canvas mt-4" type="line" height="300px" [data]="insightBasicData" [options]="insightBasicOptions"></p-chart>
            </div>
          </div>
        </div>
      </div>
    </div>





  </div>
  <!-- content-wrapper ends -->
  <!-- partial:partials/_footer.html -->
</app-sidebar>