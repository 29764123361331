import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/data-transfer.service';
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormArray,
  FormControl,
} from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-edit-user-role',
  templateUrl: './add-edit-user-role.component.html',
  styleUrls: ['./add-edit-user-role.component.scss'],
})
export class AddEditUserRoleComponent implements OnInit {
  p: number = 1;
  AddRole = false;
  showForm = false;
  queryParam: any;
  selectedMenuIds: number[] = [];
  term: string;
  addNewUserRoleForm: FormGroup;
  submitted = false;
  title = 'Add New';
  isReadonly = false;
  RO_isActive: any;
  MenuList:any=[
    {
      AM_id:1,
      AM_title:"Insights"
    },
    {
      AM_id:3,
      AM_title:"Grad Schemes"
    },
    {
      AM_id:4,
      AM_title:"Collection"
    },
    {
      AM_id:5,
      AM_title:"Sectors"
    }
  ]
  roleData: any;
  constructor(
    private formBuilder: FormBuilder,
    private dataTransferService: DataTransferService,
    private toastr: ToastrService,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
    private activeRoute: ActivatedRoute
  ) {

    this.addNewUserRoleForm = this.formBuilder.group({
      UT_title: ['', Validators.required],
      UT_description: ['',Validators.required],
      UT_isActive: ['',Validators.required],
      ADM_access: this.formBuilder.array([], Validators.required),
    });

    const state = this.router.getCurrentNavigation()?.extras.state;
    if (state) {
      this.title = state?.title;
       this.roleData=state?.roleData;
       console.log("roleData : ",this.roleData);
       
      }
  }


  ngOnInit(): void {
    if(this.title=='View'){
      this.addNewUserRoleForm.patchValue(this.roleData);
    }
  }


  

  removeMenu(index: number, formControlName: string) {
    const formArray = this.addNewUserRoleForm.get(formControlName) as FormArray;
    formArray.removeAt(index);
    this.selectedMenuIds.splice(index, 1);
  }



  onBackspaceKey(event: KeyboardEvent, formControlName: string) {
    if (event.key == 'Backspace') {
      const inputElement = event.target as HTMLInputElement;
      if(inputElement.value===''&&!event.shiftKey){
      event.preventDefault();
      this.removeLastMenu(formControlName);
      }
    }
  }

  removeLastMenu(formArrayName: string) {
    const formArray = this.addNewUserRoleForm.get(formArrayName) as FormArray;
    if (formArray.length > 0) {
      formArray.removeAt(formArray.length - 1);
    }
  }

  addMenu(event: any) {
    const menuTitle = event.target.value;    
    if (menuTitle.trim() != '') {
      const selectedMenu = this.MenuList.find((menu:any) => menu.AM_title === menuTitle);
      if (selectedMenu && !this.selectedMenuIds.includes(selectedMenu.AM_id)) {
        this.selectedMenuIds.push(selectedMenu.AM_id);
        const formArray = this.addNewUserRoleForm.get('ADM_access') as FormArray;
        formArray.push(new FormControl({ accessId: selectedMenu.AM_id }));
        event.target.value = '';
      }
    }
  }
  
  getMenuTitle(id: number): string {
    // console.log("this.id",id)
    // console.log("ID:", id); // Log the id parameter
    var menu = this.MenuList.find((menu:any) => menu.AM_id === id);
        return menu ? menu.AM_title : '';
  }
  
  saveRole(){
    console.log("Post data... :",this.addNewUserRoleForm.value);
    if(this.addNewUserRoleForm.invalid){
      this.toastr.info("Please fill all required fields.");
    }else{
    this.dataTransferService.addUserTypesRole(this.addNewUserRoleForm.value).subscribe((res:any)=>{
      if(res.statusCode==200){
        this.toastr.success("New user role added successfully.");
        this.dataTransferService.getAllRole();
        this.router.navigate([`/admins/roles`]);
      }
      else{
        this.toastr.error("Unable to add new user role");
        console.log("Unable to add new user role",res?.error);
        
      }
    })
  }
}

}
