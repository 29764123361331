import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataTransferService } from 'src/app/shared/services/data-transfer.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private dataTransferService: DataTransferService,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.form = this.formBuilder.group(
      {
        email: ['', [Validators.required, Validators.email]],
        password: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/
            ),
          ],
        ],
        confirmPassword: ['', Validators.required],
        otp: ['', Validators.required],
      },
      {
        validator: this.passwordMatchValidator,
      }
    );
  }

  ngOnInit() {}

  passwordMatchValidator(formGroup: FormGroup) {
    const passwordControl = formGroup.get('password');
    const confirmPasswordControl = formGroup.get('confirmPassword');

    if (passwordControl?.value !== confirmPasswordControl?.value) {
      confirmPasswordControl?.setErrors({ mismatch: true });
    } else {
      confirmPasswordControl?.setErrors(null);
    }
  }

  setUserPassword() {
    if (this.form.invalid) {
      this.toastr.info('Please fill all required fields correctly.');
    } else {
      this.ngxSpinnerService.show('globalSpinner');

      console.log('Form data being submitted:', this.form.value);

      this.dataTransferService.setPassword(this.form.value).subscribe(
        (res: any) => {
          this.ngxSpinnerService.hide('globalSpinner');

          if (res.statusCode === 200) {
            this.toastr.success('You have set the password successfully.');
            this.form.reset();

            // Delay navigation to allow the success message to be seen
            setTimeout(() => {
              this.router.navigate([`/login`]);
            }, 1000);
          } else {
            this.toastr.error('Unable to set the password.');
          }
        },
        (error: any) => {
          this.ngxSpinnerService.hide('globalSpinner');
          this.toastr.error('Unable to set the password.');
          console.error('Error while setting password:', error);
        }
      );
    }
  }
}
