import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DataTransferService } from '../services/data-transfer.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  userRole: number = 0;
  accessMenuList: any = [];
  actionLinks: any = [];
  adminLinks: any = [
    {
      AC_id: 913,
      AC_title: 'Portal User',
      AC_urlSlug: '/admins/users',
    },
    // {
    //   AC_id: 912,
    //   AC_title: 'User Roles',
    //   AC_urlSlug: '/admins/roles',
    // }
  ];
  active: any;
  userTypeId: any;
  constructor(
    private dataTransferService: DataTransferService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService
  ) {
    this.userRole = Number(sessionStorage.getItem('roleId'));
    // In your other component where you want to retrieve the userTypeId
    this.userTypeId = sessionStorage.getItem('userTypeId');
    console.log('User Type ID:', this.userTypeId);

    const adminModulesData = sessionStorage.getItem('adminModules');
    if (adminModulesData) {
      try {
        this.accessMenuList = JSON.parse(adminModulesData);
        // console.log('accessMenuList : ', this.accessMenuList);
      } catch (error) {
        console.error('Error parsing JSON: ', error);
      }
    }


    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.resetSidebar();
      }
    });

  }


  toggleSidebar() {
    const sidebar = document.getElementById('sidebar');
    if (sidebar) {
      sidebar.classList.toggle('active');
    }
  }

  resetSidebar() {
    // Reset sidebar to initial state
    const sidebar = document.getElementById('sidebar');
    if (sidebar && sidebar.classList.contains('active')) {
      sidebar.classList.remove('active');
    }
  }
  
  isMenuActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  ngOnInit(): void {
    // this.getRoleAcccess();
  }
  logout() {
    this.ngxSpinnerService.show('globalSpinner');
    console.log('Destroy');
    sessionStorage.clear();
    this.router.navigate(['']);
    this.ngxSpinnerService.hide('globalSpinner');
  }

  //  logout() {
  //   console.log('Destory')
  //   sessionStorage.clear();
  //   this.router.navigate([``]);
  // }

  // getRoleAcccess() {
  //   this.dataTransferService.getAccessLinksForRole(this.userRole).subscribe((res: any) => {
  //     console.log('getAccessLinksForRole',res);
  //     if (res.status == 200) {
  //       res.data.forEach((element:any) => {
  //         if(element.AC_urlSlug.startsWith("/actions")){
  //           this.actionLinks.push(element);
  //         }else if(element.AC_urlSlug.startsWith("/admins")){
  //           this.adminLinks.push(element);
  //         }else{
  //           this.accessLinks.push(element);
  //         }
  //       });
  //     }
  //   })
  // }

  showModal(){
    const modal=document.getElementById('logOutModal');
    if(modal!=null){
      modal.style.display='block';
    }
  }

  hideModal(){
    const modal=document.getElementById('logOutModal');
    if(modal!=null){
      modal.style.display='none';
    }
  }
}
