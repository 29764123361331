import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminsRoutingModule } from './admins-routing.module';
import { AdminsComponent } from './admins.component';
import { SidebarModule } from '../shared/sidebar/sidebar.module';
import { UsersComponent } from './users/users.component';
import { RoleComponent } from './role/role.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { AddEditUserRoleComponent } from './role/add-edit-user-role/add-edit-user-role.component';
import { AddEditUserComponent } from './users/add-edit-user/add-edit-user.component';
import { SetPasswordComponent } from './users/set-password/set-password.component';
import { CamelCasePipe } from './camel-case-admin.pipe';

@NgModule({
  declarations: [
    AdminsComponent,
    UsersComponent,
    RoleComponent,
    AddEditUserRoleComponent,
    AddEditUserComponent,
    SetPasswordComponent,
    CamelCasePipe
  ],
  imports: [
    CommonModule,
    AdminsRoutingModule,
    SidebarModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgbTooltipModule, 
    Ng2SearchPipeModule,
  ],
 
})
export class AdminsModule { }
