<app-sidebar>
    <div class="content-wrapper fade-in">

        <div class="row mb-4 head-Home">
            <!-- Add and search bar -->
            <div class="col-lg-3 mb-2 mb-lg-0">
                <button type="submit" (click)="addRecord();" class="btn btn-primary w-100">Add New User</button>
            </div>
            <div class="col-lg-6">
                <div class="input-group">
                    <input type="text" [(ngModel)]="term" class="form-control shadow-sm rounded-start"
                        placeholder="Search here" style="width: 100%;" aria-label="Search now">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header card-title bg-primary rounded-top text-white text-center  mb-0">Users</div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-sm table-hover">
                                <thead>
                                    <tr>
                                        <th>
                                            Name
                                        </th>

                                        <th>
                                            Email
                                        </th>

                                        <!-- <th>
                                             Phone
                                        </th>
                                        <th>
                                            Industry
                                        </th> -->
                                        <th>
                                            Role
                                        </th>
                                        <!-- <th>
                                            Action Buttons
                                        </th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let user of usersList| filter: term|paginate: {itemsPerPage:7, currentPage:p}">
                                        <td >{{user.UA_name | camelCase}}</td>
                                        <td class="py-3">{{user.UA_email}}</td>
                                        <!-- <td>{{user.U_industry}}</td>
                                        <td>{{user.US_phone}}</td> -->
                                        <!-- <td>{{user.US_password}}</td>-->
                                        <td>{{user.roleTitle}}</td>

                                     <!--   <td>
                                            <div class="btn-group" role="group" aria-label="Basic example">
                                                <button type="button" class="btn btn-primary btn-sm" placement="top"
                                                    ngbTooltip="Edit">

                                                    <i class="ti-pencil text-white"></i>
                                                </button>
                                                 <button type="button" class="btn btn-primary btn-sm"
                                                placement="top" ngbTooltip="View">
                                                    <i class="ti-eye"></i>
                                                </button> 
                                                <button type="button" class="btn btn-primary btn-sm" placement="top"
                                                    ngbTooltip="Delete" data-toggle="modal" data-target="#my-modal">
                                                    <i class="ti-trash"></i>
                                                </button>
                                            </div>
                                        </td>-->


                                    </tr>
                                </tbody>
                            </table>
                            <pagination-controls (pageChange)="p = $event"
                                class="ml-1 text-center"></pagination-controls>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="my-modal" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content border-0">
                    <div class="modal-body p-0">
                        <div class="card border-0 p-sm-3 p-2 justify-content-center">
                            <div class="card-header float-end pb-0 bg-white border-0 ">
                                <div class="row">
                                    <div class="col ml-auto"><button type="button" class="close" data-dismiss="modal"
                                            aria-label="Close"> <span aria-hidden="true">&times;</span> </button></div>
                                </div>
                                <h3 class="text-center">Confirm Delete</h3>
                                <br>
                                <p class="font-weight-bold mb-2 text-center"><b> Are you shure to delete this ?</b></p>
                            </div>
                            <br>
                            <div class="card-body px-sm-4 mb-2 pt-1 pb-0">
                                <div class="row justify-content-center no-gutters">
                                    <div class="col-auto"><button type="button" class="btn btn-primary mx-3 text-white"
                                            data-dismiss="modal">Cancel</button></div>
                                    <div class="col-auto"><button type="button" class="btn btn-danger px-4"
                                            data-dismiss="modal">Delete</button></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-sidebar>