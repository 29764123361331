<div class="container-scroller">
  <div class="container-fluid page-body-wrapper full-page-wrapper">
    <div class="content-wrapper d-flex align-items-center auth px-0">
      <div class="row w-100 mx-0">
        <div class="col-lg-4 mx-auto">
          <div class="auth-form-light text-left py-5 px-4 px-sm-5">
            <div class="brand-logo text-center">
              <img src="./assets/images/Primary Logo_transperant.png" alt="GradVisor logo" width="165px" height="62px">
            </div>
            <h4 class="text-center">Welcome to Gradvisor.</h4>
            <h5 class="font-weight-light text-center">Sign in to continue.</h5>
            <form class="pt-3" [formGroup]="loginForm" (ngSubmit)="onSubmit()">

              <div class="form-group">
                <input type="email" class="form-control form-control-lg" formControlName="UA_email"
                  placeholder="Please Enter Registered Email">
                <div *ngIf="UA_email?.invalid && (UA_email?.dirty || UA_email?.touched)" class="alert alert-danger">
                  <div *ngIf="UA_email?.errors?.required">Email is required.</div>
                  <div *ngIf="UA_email?.errors?.email">Invalid email address.</div>
                </div>
              </div>


              <div class="form-group password-wrapper">
                <input type="{{ passwordHidden ? 'password' : 'text' }}" class="form-control form-control-lg"
                  formControlName="UA_password" placeholder="Password">
                <span class="password-toggle-btn" (click)="togglePasswordVisibility()">
                  <i class="fa" [ngClass]="passwordHidden ? 'fa-eye' : 'fa-eye-slash'"></i>
                </span>
              </div>

              <div class="mt-3">
                <button type="submit" class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn">SIGN
                  IN</button>
                <!-- <a class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" href="../../index.html">SIGN IN</a> -->
              </div>
              <!-- <div class="my-2 d-flex justify-content-between align-items-center">
                  <div class="form-check">
                    <label class="form-check-label text-muted">
                      <input type="checkbox" class="form-check-input">
                      Keep me signed in
                    </label>
                  </div>
                  <a href="#" class="auth-link text-black">Forgot password?</a>
                </div> -->
              <!--  <div class="mb-2">
                  <button type="button" class="btn btn-block btn-facebook auth-form-btn">
                    <i class="ti-facebook mr-2"></i>Connect using facebook
                  </button>
                </div>
                --> <!-- <div class="text-center mt-4 font-weight-light">
                  Don't have an account? <a routerLink="/register" class="text-primary">Create</a>
                </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
  <ngx-spinner name="globalSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" template="<img src='assets/loader/Spinner-1s-200px-2.svg' alt='' 
      >" [fullScreen]="true" type="ball-scale-multiple">
    <p class="mt-5" style="color: white"> Loading... </p>
  </ngx-spinner>
</div>
