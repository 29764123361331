import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import {  NgbModule,NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from "ngx-spinner";
import { DashboardComponent } from './dashboard/dashboard.component';
import { SidebarModule } from './shared/sidebar/sidebar.module';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { HttpConfigInterceptor } from './shared/interceptors/http-config.interceptor';
import { AdminsModule } from './admins/admins.module';
import { SettingComponent } from './setting/setting.component';
import { TagInputModule } from 'ngx-chips';
import { UnauthorizePageComponent } from './Error-Pages/unauthorize-page/unauthorize-page.component';
import { PageNotFoundComponent } from './Error-Pages/page-not-found/page-not-found.component';
import {AccordionModule} from 'primeng-lts/accordion';     //accordion and accordion tab
import { MenubarModule } from 'primeng-lts/menubar';
import {ChartModule} from 'primeng-lts/chart';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgCircleProgressModule } from 'ng-circle-progress';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    RegisterComponent,
    SettingComponent,
    UnauthorizePageComponent,
    PageNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    TagInputModule,
    AppRoutingModule,
    SidebarModule,
    AdminsModule,
    HttpClientModule, 
    FormsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    AccordionModule,
    MenubarModule,
    ChartModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    // NgSelectModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    
    NgbModule,NgbTooltipModule,
    NgCircleProgressModule.forRoot({})
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: HttpConfigInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { }
