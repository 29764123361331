import { Component, OnInit } from '@angular/core';
import { DataTransferService } from 'src/app/shared/services/data-transfer.service';
import { PrimeNGConfig } from 'primeng-lts/api';
import { Observable, Subscription } from 'rxjs';
import { AppConfig, AppConfigService } from '../app-config.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  basicData: any;
  basicOptions: any;
  subscription: Subscription;
  selectedButton: string = 'daily';

  config: AppConfig | undefined;
  days: string[] = [];
  weeks: string[] = [];
  months: string[] = [];

  sharerData: number[] = [];
  seekerData: number[] = [];
  
  // selectedDays: string;
  // selectedMonth: string;
  // selectedWeek: number;
  count: any = [];
  data: string[] = [];  // Declare data as an array of strings
  draftInsights: any;
  pendingInsights: any;
  TotalInsights: any;
  rejectedInsights: any;
  totalSharer: any;
  totalSeeker: any;
  SectorData: any;
  selectedSector: string = 'All';
  selectedTimeframe: string = 'daily';
  insightBasicData: any;
  insightBasicOptions: any;
  // isMobile: boolean;

  constructor(
    private dataTransferService: DataTransferService,
    private primengConfig: PrimeNGConfig,
    private configService: AppConfigService,
    private ngxSpinnerService:NgxSpinnerService,
    private http: HttpClient, private router: Router
  ) { }

  ngOnInit() {
    this.getAllInsightSummary();   
    this.getUserChartData(this.selectedButton);
    this.setChartOptions();
    this.getAllInsightChartData(this.selectedTimeframe,this.selectedSector);
    window.addEventListener('resize', this.setChartOptions.bind(this));
    this.getAllSectorsData();
    
    // this.getExpectedInsight().subscribe(
    //   response => {
    //     console.log(response.message);
    //   },
    //   error => {
    //     console.error('Error:', error);
    //   }
    // );
    // this.getRejectedInsight().subscribe(
    //   response => {
    //     console.log(response.message);
    //   },
    //   error => {
    //     console.error('Error:', error);
    //   }
    // );

  }

  setChartOptions() {
    const isMobile = window.innerWidth <= 768; // Define mobile screen width
    this.basicOptions = {
      responsive: true,
      plugins: {
        legend: {
          position: 'top',
        },
        title: {
          display: false,
          text: 'Chart Title'
        }
      },
      scales: {
        x: {
          display: true,
          title: {
            display: false,
            text: 'Dates'
          },
          ticks: {
            font: {
              size: isMobile ? 5 : 10,
              weight: 'bold'
            }
          }
        },
        y: {
          display: true,
          title: {
            display: false,
            text: 'Count'
          },
          ticks: {
            callback: function(value: any, index: any, values: any) {
              return Number.isInteger(value) ? value : null;
            },
            font: {
              weight: 'bold'
            }
          }
        }
      }
    };
  
    // Update the chart to apply the new options
    if (this.basicData) {
      this.updateChartData(this.selectedButton);
    }
  }

  updateChartData(timeframe: string) {
    let labels: string[];
    let sharerData: number[];
    let seekerData: number[];

    if (timeframe === 'weekly') {
      labels = this.weeks;
    } else if (timeframe === 'monthly') {
      labels = this.months;
    } else {
      labels = this.days;
    }

    sharerData = this.sharerData;
    seekerData = this.seekerData;

    this.basicData = {
      labels: labels,
      datasets: [
        {
          label: 'Sharer',
          data: sharerData,
          fill: false,
          borderColor: '#FFA726',
          tension: .4
        },
        {
          label: 'Seeker',
          data: seekerData,
          fill: false,
          borderColor: '#4B49AC',
          tension: .4
        }
      ]
    };
  }

  onTimeframeChange(event: any) {
    this.selectedButton = event.target.value;
    console.log("Type : ",this.selectedButton);
    this.getUserChartData(this.selectedButton);
  }

  getUserChartData(type: string) {
    console.log("Fetching chart data for type:", type);
    // this.ngxSpinnerService.show('globalSpinner');
    this.dataTransferService.getChartData(type).subscribe((res: any) => {
      if (res.status == 200) {
        // this.ngxSpinnerService.hide('globalSpinner');
        if (type === 'daily') {
          this.days = res.data.sharers.map((element: any) => new Date(element.startDate)).sort((a:any, b:any) => a - b).map((date: Date) => date.toLocaleDateString());
          // this.days = res.data.sharers.map((element: any) => new Date(element.startDate).toLocaleDateString());
          this.sharerData = res.data.sharers.map((element: any) => element.count);
          this.seekerData = res.data.seekers.map((element: any) => element.count);
        } else if (type === 'weekly') {
          this.weeks = res.data.sharers.map((element: any) => ({startDate: new Date(element.startDate),endDate: new Date(element.endDate)
        })).sort((a:any, b:any) => a.startDate - b.startDate).map((element: any) => `${element.startDate.toLocaleDateString()} To ${element.endDate.toLocaleDateString()}`);
        // this.weeks = res.data.sharers.map((element: any) => `${new Date(element.startDate).toLocaleDateString()} To ${new Date(element.endDate).toLocaleDateString()}`);
          this.sharerData = res.data.sharers.map((element: any) => element.count);
          this.seekerData = res.data.seekers.map((element: any) => element.count);
        } else if (type === 'monthly') {
          this.months = res.data.sharers.map((element: any) => ({startDate: new Date(element.startDate),endDate: new Date(element.endDate)
        })).sort((a:any, b:any) => a.startDate - b.startDate).map((element: any) => `${element.startDate.toLocaleDateString()} To ${element.endDate.toLocaleDateString()}`); 

          // this.months = res.data.sharers.map((element: any) => `${new Date(element.startDate).toLocaleDateString()} To  ${new Date(element.endDate).toLocaleDateString()}`);  //toLocaleString('default', { month: 'long' }));
          this.sharerData = res.data.sharers.map((element: any) => element.count);
          this.seekerData = res.data.seekers.map((element: any) => element.count);
        }

        this.updateChartData(type);
      }
    },(error:any)=>{
      this.ngxSpinnerService.hide('globalSpinner');
      console.log("error",error);
    }
  );
  }

  // getExpectedInsight(): Observable<any> {
  //   return this.http.get("https://j74r012f8i.execute-api.ap-south-1.amazonaws.com/api/saveInsightExpectedNotifications");
  // }

  // getRejectedInsight(): Observable<any> {
  //   return this.http.get("https://j74r012f8i.execute-api.ap-south-1.amazonaws.com/api/saveInsightRejectedNotifications");
  // }
   
  getAllInsightSummary(){
    this.dataTransferService.getAllInsightSummary().subscribe((res:any)=>{
      if(res.status===200){
        // this.draftInsights=res.Insight_count.Submitted_Insights;
        // this.pendingInsights=res.Insight_count.Published_Insights;
        this.TotalInsights=res.Insight_count.Approved_Insights;
        // this.rejectedInsights=res.Insight_count.Rejected_Insights;
        this.totalSeeker=res.User_count.U_isSharer_0;
        this.totalSharer=res.User_count.U_isSharer_1;
      }else{
        console.log("Unble to fetch data");
        
      }
    },(error:any)=>{
      this.ngxSpinnerService.hide('globalSpinner');
      console.log("error",error);
    });
  }

 onSectorChange(event: any): void {
    this.selectedSector = event.target.value;
    this.getAllInsightChartData(this.selectedTimeframe, this.selectedSector);
  }

  onInsightTimeframeChange(event: any): void {
    this.selectedTimeframe = event.target.value;
    this.getAllInsightChartData(this.selectedTimeframe, this.selectedSector);
  }

  getAllInsightChartData(interval: string, selectedSector: string): void {
    this.ngxSpinnerService.show('globalSpinner');
    this.dataTransferService.getAllInsightChartData(interval).subscribe((res: any) => {      
      if (res.status === 200) {
        let filteredData = res.data;
        this.ngxSpinnerService.hide('globalSpinner');
        if (selectedSector !== 'All') {
          // Filter data to include only the selected sector
          filteredData = filteredData.map((item: any) => ({
            ...item,
            industryInsightCounts: item.industryInsightCounts.filter((insight: any) => insight.industryId === selectedSector)
          }));
        } else {
          // Aggregate data across all sectors
          filteredData = filteredData.map((item: any) => {
            const allIndustries = item.industryInsightCounts.reduce((acc: any[], insight: any) => {
              const existing = acc.find((i: any) => i.industryId === insight.industryId);
              if (existing) {
                existing.counts.Submitted_Insights += insight.counts.Submitted_Insights;
                existing.counts.Published_Insights += insight.counts.Published_Insights;
                existing.counts.Approved_Insights += insight.counts.Approved_Insights;
                existing.counts.Rejected_Insights += insight.counts.Rejected_Insights;
              } else {
                acc.push({...insight});
              }
              return acc;
            }, []);
  
            return {
              ...item,
              industryInsightCounts: allIndustries
            };
          });
        }
  
        // Process data for chart
        const chartData = this.processDataForInsightChart(filteredData, interval);
        this.insightBasicData = chartData.data;
        this.insightBasicOptions = chartData.options;
      } else {
        console.log("Unable to fetch data");
      }
    },(error:any)=>{
      this.ngxSpinnerService.hide('globalSpinner');
      console.log("error",error);
      
    });
  }

  processDataForInsightChart(data: any[], interval: string) {
    let labels: string[] = [];
    let publishedInsightsData: number[] = [];
    let submittedInsightsData: number[] = [];
    let approvedInsightsData: number[] = [];
    let rejectedInsightsData: number[] = [];
  
    const fontSize = window.innerWidth < 768 ? 5 : 10;
  
    data.forEach((item: any) => {
      // Determine label based on interval
      let label: any;
      if (interval === 'daily') {
        label = new Date(item.startDate).toLocaleDateString();
      } else if (interval === 'weekly') {
        label = `${new Date(item.startDate).toLocaleDateString()} - ${new Date(item.endDate).toLocaleDateString()}`;
      } else if (interval === 'monthly') {
        const startDate = new Date(item.startDate);
        const endDate = new Date(item.endDate);
        label = `${startDate.toLocaleDateString()} - ${endDate.toLocaleDateString()}`;
      }
      
      labels.push(label);
  
      // Aggregate data for each type of insight
      const aggregatedCounts = item.industryInsightCounts.reduce((acc: any, insight: any) => {
        acc.Published_Insights += insight.counts.Published_Insights || 0;
        acc.Submitted_Insights += insight.counts.Submitted_Insights || 0;
        acc.Approved_Insights += insight.counts.Approved_Insights || 0;
        acc.Rejected_Insights += insight.counts.Rejected_Insights || 0;
        return acc;
      }, { Published_Insights: 0, Submitted_Insights: 0, Approved_Insights: 0, Rejected_Insights: 0 });
  
      publishedInsightsData.push(aggregatedCounts.Published_Insights);
      submittedInsightsData.push(aggregatedCounts.Submitted_Insights);
      approvedInsightsData.push(aggregatedCounts.Approved_Insights);
      rejectedInsightsData.push(aggregatedCounts.Rejected_Insights);
    });
  
    // Chart data and options
    return {
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Draft',
            data: submittedInsightsData,
            borderColor: '#6c757d', // Badge color for Draft
            backgroundColor: 'rgba(108, 117, 125, 0.2)', // Fill color for Draft
            borderWidth: 2
          },
          {
            label: 'Pending',
            data: publishedInsightsData,
            borderColor: '#ffc107', // Badge color for Pending
            backgroundColor: 'rgba(255, 193, 7, 0.2)', // Fill color for Pending
            borderWidth: 2
          },
          {
            label: 'Approved',
            data: approvedInsightsData,
            borderColor: '#28a745', // Badge color for Approve
            backgroundColor: 'rgba(40, 167, 69, 0.2)', // Fill color for Approve
            borderWidth: 2
          },
          {
            label: 'Rejected',
            data: rejectedInsightsData,
            borderColor: '#dc3545', // Badge color for Reject
            backgroundColor: 'rgba(220, 53, 69, 0.2)', // Fill color for Reject
            borderWidth: 2
          }
        ]
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: false,
            text: 'Chart Title'
          }
        },
        scales: {
          x: {
            display: true,
            title: {
              display: false,
              text: 'Dates'
            },
            ticks: {
              font: {
                size: fontSize,
                weight: 'bold'
              }
            }
          },
          y: {
            display: true,
            title: {
              display: false,
              text: 'Count'
            },
            ticks: {
              callback: function(value: any) {
                return value >= 0 && Number.isInteger(value) ? value : null;
              },
              beginAtZero: true,
              font: {
                weight: 'bold'
              } 
            }
          }
        }
      }
    };
  }
  
  getAllSectorsData(){
    // this.ngxSpinnerService.show('globalSpinner');
    this.dataTransferService.getIndustryData().subscribe({
      next:(res:any)=>{
        if (res.status === 200) {
          this.SectorData = res.data;
          console.log("SectorData : ",this.SectorData);
          // this.ngxSpinnerService.hide('globalSpinner');
        } else {
          this.ngxSpinnerService.hide('globalSpinner');
          console.error('Failed to fetch sectors. Status:', res.status);
        }
      },
      error: (error: any) => {
        this.ngxSpinnerService.hide('globalSpinner');
        console.error('Error occurred while fetching sectors:', error);
      },
    })
  }
   
}