<app-sidebar>
  <div class="content-wrapper fade-in">
    <div class="row mb-4 mx-2 head-Home">
      <!-- Add and search bar -->
      <div class="col-lg-3 mb-2 mb-lg-0">
        <button type="submit" (click)="goToAddRoleForm();" class="btn btn-primary w-100">Add New User Role</button>
      </div>
      <div class="col-lg-6">
        <div class="input-group">
          <input type="text" [(ngModel)]="term" class="form-control shadow-sm rounded-start" placeholder="Search here"
            style="width: 100%;" aria-label="Search now">
        </div>
      </div>
    </div>  
    
    

    <div class="row" >
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-header card-title bg-primary rounded-top text-white text-center  mb-0">User Roles</div>
          <div class="card-body">
            <!-- <h4 class="card-title">Role</h4> -->
            <div class="table-responsive">
              <table class="table table-sm table-hover">
                <thead>
                  <tr>
                    <th>
                     User Role Title
                    </th>
                    <th>
                      Is User Role active
                    </th>
                    <th class="align-items-end">
                      Action Buttons
                    </th>
                  </tr>
                </thead>
                <tbody>

                  <tr *ngFor="let role of roleList| filter: term|paginate : { itemsPerPage:10, currentPage:p}">

                    <td>{{role.UT_title}}</td>
                    <!-- <td class="d-inline-block text-truncate" style="max-width: 150px;">{{role.RO_description}}</td> -->
                    <td><span
                        class="badge badge-pill badge-{{role.UT_isActive==1? 'success':'danger'}}">{{role.UT_isActive==1?
                        'Yes':'No'}} </span></td>
                    <td>
                      <div class="btn-group" role="group">
                        <button type="button" class="btn btn-primary btn-sm" 
                          placement="top" ngbTooltip="Edit">

                          <i class="ti-pencil text-white" aria-hidden="true"></i>

                        </button>
                        <!-- <button type="button" (click)="viewUserRole(role);" class="btn btn-primary btn-sm" 
                          placement="top" ngbTooltip="View">
                          <i class="ti-eye"></i>
                        </button> -->
                       
                      <button type="button" (click)="showRoleDeleteForm(role.UT_id)" class="btn btn-primary btn-sm" data-toggle="modal" data-target="#myModal"
                      placement="top" ngbTooltip="Delete">
                      <i class="ti-trash"></i>
                    </button>
                      </div>
                    </td>
                  </tr>
                  <div id="myModal" class="modal fade" role="dialog">
                    <!--Modal-->
                    <div class="modal-dialog">
                      <!--Modal Content-->
                      <div class="modal-content">
                        <!-- Modal Header-->
                        <div class="modal-header">
                          <h3>Delete warning!!!</h3>
                          <!--Close/Cross Button-->
                          <button type="button" class="close" data-dismiss="modal"
                            style="color: white;">&times;</button>
                        </div>
                        <!-- Modal Body-->
                        <div class="modal-body text-center">
                          <i class="ti-trash" style="color: red;"></i>
                          <h2> <strong>Are you sure?</strong></h2><br>
                          Do you really want to delete this user role?
                        </div>

                        <!-- Modal Footer-->

                        <div class="modal-footer text-center">
                          <button  class="btn btn-primary" (click)="deleteUserRole()" data-dismiss="modal">Yes</button>
                          <a href="" class="btn btn-primary" data-dismiss="modal">Cancel</a>
                        </div>

                      </div>

                    </div>

                  </div>
                </tbody>
              </table>
              <pagination-controls (pageChange)="p = $event" class="ml-1 text-center">
              </pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
</app-sidebar>
    