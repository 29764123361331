<div class="content-wrapper">
    <div class="container">
        <div class="card-header card-title bg-primary rounded-top text-white text-center  mb-3">Set Password</div>
        <div class="container-fluid">
<form [formGroup]="form" (ngSubmit)="setUserPassword()">

    <div class="row">

    <div class="form-group col-lg-6">
      <label for="email">Mail</label>
      <input type="email" class="form-control" id="email" formControlName="email">
    </div>

    <div class="form-group col-lg-6">
        <label for="otp">Passcode</label>
        <input type="text" class="form-control" id="otp" formControlName="otp">
      </div>

    </div>

    <div class="row">
    <div class="form-group col-lg-6">
      <label for="password">Password</label>
      <input type="password" class="form-control" id="password" formControlName="password">
      <div *ngIf="form.get('password')?.invalid && (form.get('password')?.dirty || form.get('password')?.touched)"
                  class="text-danger">
                  <div *ngIf="form.get('password')?.errors?.required">Password is required.</div>
                  <div *ngIf="form.get('password')?.errors?.minlength">Password must be at least 8 characters long.</div>
                  <div *ngIf="form.get('password')?.errors?.pattern">Password must contain at least:
                    <ul>
                      <li>One uppercase letter</li>
                      <li>One lowercase letter</li>
                      <li>One number</li>
                      <li>One special character</li>
                    </ul>
                  </div>
                </div>      
    </div>
    <div class="form-group col-lg-6">
      <label for="confirmPassword">Confirm Password</label>
      <input type="password" class="form-control" id="confirmPassword" formControlName="confirmPassword">
      <div *ngIf="form.get('confirmPassword')?.hasError('mismatch')" class="text-danger">
        Passwords do not match
      </div>
    </div>
    </div>
   <div class="text-center">
    <button type="submit" class="btn btn-primary">Submit</button>
</div>
  </form>
  </div>
</div>
</div>