import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminsComponent } from './admins.component';
import { RoleComponent } from './role/role.component';
import { UsersComponent } from './users/users.component';
import { AddEditUserComponent } from './users/add-edit-user/add-edit-user.component';
import { AddEditUserRoleComponent } from './role/add-edit-user-role/add-edit-user-role.component';
import { SetPasswordComponent } from './users/set-password/set-password.component';

const routes: Routes = [{ path: '',
  children:[
    {path: '',component: AdminsComponent},
    {path: 'roles', component: RoleComponent}, 
    {path:'roles/add-edit-user_role', component:AddEditUserRoleComponent},
    {path: 'portal-users', component: UsersComponent},
    {path:'portal-users/add-portal-user',component:AddEditUserComponent},
    {path:'portal-users/set-user-password',component:SetPasswordComponent}


  ]}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminsRoutingModule { }
