import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/data-transfer.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-add-edit-user',
  templateUrl: './add-edit-user.component.html',
  styleUrls: ['./add-edit-user.component.scss']
})
export class AddEditUserComponent implements OnInit {

  usersList:any;
  AddUser = false;
  showForm = false;
  roles:any;
  p:number=1;
  term: string;
  isReadonly = false;
  userForm : FormGroup;
  submitted = false;
  title = 'Add New';
  queryParam :any;
  
  constructor(private formBuilder: FormBuilder,
    private dataTransferService:DataTransferService,
    private toastr:ToastrService,
    private ngxSpinnerService: NgxSpinnerService,
    private domSanitizer: DomSanitizer,
    private router:Router,
    private route: ActivatedRoute) { 

      this.userForm = this.formBuilder.group({
      
        name:['',Validators.required],
        email: ['', [Validators.required, Validators.email]],
        roleId:['',Validators.required]
      })

      this.route.queryParams.subscribe(param=>{
        this.title = param['title'];
      });

    }

    get f(){
      return this.userForm.controls;
    }

  ngOnInit(): void {
    this.getAllUserRole();
    }

 /*  get f() {
    return this.userForm.controls;
  }
 */
  // onSubmit(){
  //   this.submitted = true;
  //   console.log('this.userForm.value',this.userForm.value);
  //   if(this.userForm.invalid) {
  //     this.toastr.info('','Please fill all required fields');
  //     return;
  //   }
  //   if(this.title == 'Edit')
  //   {
  //     console.log('user Edit',this.editId)
  //     this.userForm.value.US_id=this.editId;
  //     this.dataTransferService.updateUser(this.userForm.value).subscribe((res:any)=>{
  //       console.log('Edit User',res);
  //       if(res.status==200){
  //         this.toastr.success('',res.message);
  //         this.showForm = false; //hide form
  //         this.getAllUsers();
  //       }else{
  //         this.toastr.error('',res.message);
  //       }
  //     })
  //   }
  //   else{
  //    
  //   }
  // }

  saveUser(){
    if(this.userForm.invalid){
      this.toastr.info("Please fill all required fields");
    }else{
      this.ngxSpinnerService.show('globalSpinner');
        console.log("Data to add new user : ",this.userForm.value);
    this.dataTransferService.addUser(this.userForm.value).subscribe({
      next:(res:any)=>{
        this.ngxSpinnerService.hide('globalSpinner');
              console.log('addUser',res);
              if(res.statusCode==200){
                this.showModal();
              }else{
                this.ngxSpinnerService.hide('globalSpinner');
                this.toastr.error('','Something Went Wrong');
              }
            
      },error:(error:any)=>{
        this.ngxSpinnerService.hide('globalSpinner');
        this.toastr.error('','Something Went Wrong');
        console.log(error);
        
      }
    })
        }
  }


  showModal(){
    const modal=document.getElementById('staticBackdrop');
    if(modal!=null){
      modal.style.display='block';
    }
  }

  hideModal(){
    const modal=document.getElementById('staticBackdrop');
    if(modal!=null){
      modal.style.display='none';
       this.dataTransferService.getAllAdminUsers();
       this.router.navigate([`/admins/portal-users`]);
    }
  }
  

  onReset(){
    this.submitted=false;
    this.userForm.reset();
  }


  getAllUserRole(){
    this.dataTransferService.getAllRole().subscribe((res:any)=>{
      console.log('getAllRoles',res);
      if(res.status==200){
        this.roles = res.data;
      }
    },(error:any)=>{
      this.ngxSpinnerService.hide('globalSpinner');
      console.log("error",error);

    })
  }

  addRecord(){
    this.isReadonly=false;
    this.title = 'Add new';
    this.onReset();
    this.showForm = true;
  }

  formReset(){
    this.userForm.reset();
  }
}

