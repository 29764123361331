import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface AppConfig {
  dark: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private configSubject = new BehaviorSubject<AppConfig>({ dark: false });
  configUpdate$ = this.configSubject.asObservable();

  get config(): AppConfig {
    return this.configSubject.value;
  }

  updateConfig(newConfig: AppConfig): void {
    this.configSubject.next(newConfig);
  }
}
