// auth.service.ts
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  isLoggedIn(): boolean {
    // Check if token exists in sessionStorage
    return !!sessionStorage.getItem('token');
  }

  // You can add more methods here if needed, like logout
}
