<div class="container-scroller">

  <nav class="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">

    <div class="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
      <!-- Brand logo for small screens -->
      <a class="navbar-brand brand-logo-mini">
        <img src="./assets/images/Primary Logo_transperant.png" alt="GradVisor logo" style="width: 100px; height: 50px;"/>
      </a>
      <!-- Brand logo for larger screens -->
      <a class="navbar-brand brand-logo"  >
        <img src="./assets/images/Primary Logo_transperant.png" alt="GradVisor Logo" title="GradVisor Logo"/>
      </a>
    </div>
    
    
    <div class="navbar-menu-wrapper d-flex align-items-center justify-content-end">
      <!-- <button class="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
            <span class="icon-menu"></span>
          </button> -->
          
      <ul class="navbar-nav navbar-nav-right">
        <li class="nav-item nav-profile dropdown">
          <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
            <img src="./assets/images/profile-icon3.png" alt="profile" />
          </a>
          <div class="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
            <!-- <a routerLink="/settings" class="dropdown-item">
              <i class="ti-settings text-primary"></i>
              Settings
            </a> -->
            <a class="dropdown-item" (click)="showModal()">
              <i class="ti-power-off text-primary"></i>
              <span class="menu-title">Logout</span>
            </a>
          </div>

           <!-- Modal -->
           <div id="logOutModal" class="modal logOutModal">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h3>Confirmation !</h3>
                  <button type="button" class="close" data-dismiss="modal" style="color: white;">&times;</button>
                </div>
                <div class="modal-body text-center">
                  <i class="fa fa-sign-out-alt" style="color: red;"></i>
                  <h3 class="mb-3" ><strong>Are you sure?</strong></h3>
                  <p>Do you really want to logout?</p>
                </div>
                <div class="modal-footer text-center">
                  <button class="btn btn-danger" (click)="logout()" data-dismiss="modal">Logout</button>
                  <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="hideModal()">Cancel</button>
                </div>
              </div>
            </div>
          </div>
          


        </li>
      </ul>
    <!-- Toggle button for sidebar -->
<button class="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button"
(click)="toggleSidebar()">
<span class="icon-menu"></span>
</button>

    </div>
  </nav>
  <!-- partial -->
  <div class="container-fluid page-body-wrapper">

    <!-- partial -->
    <!-- partial:../../partials/_sidebar.html -->
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <ul class="nav">
        <!-- <li class="nav-item" *ngFor="let link of accessLinks">
          <a class="nav-link" routerLink="{{link.AC_urlSlug}}">
            <i class="icon-grid menu-icon"></i>
            <span class="menu-title">{{link.AC_title}}</span>
          </a>
        </li> -->
       <!-- <li class="nav-item" *ngIf="actionLinks.length>0">
          <a class="nav-link" data-toggle="collapse" href="#actions" aria-expanded="false" aria-controls="actions">
            <i class="icon-layout menu-icon"></i>
            <span class="menu-title">Actions</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="actions">
            <ul class="nav flex-column sub-menu" *ngFor="let link of actionLinks">
               <li class="nav-item"> <a class="nav-link" routerLink="{{link.AC_urlSlug}}">{{link.AC_title}}</a>
              </li> 
              <li class="nav-item"> <a class="nav-link" [routerLink]="['#']">Country</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="['#']">Ethnicity</a></li>
               <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/category']">Category</a></li>
                  <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/category-type']">Categary Type</a></li>
                  <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/options']">Options</a></li>
                  <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/rto']">RTO</a></li>
                  <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/variables']">Variables</a></li>
            </ul>
          </div>
        </li>-->
        <!-- <li class="nav-item" *ngIf="adminLinks.length>0">
          <a class="nav-link" data-toggle="collapse" href="#admin" aria-expanded="false" aria-controls="admin">
            <i class="icon-head menu-icon"></i>
            <span class="menu-title">Admin</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="admin">
            <ul class="nav flex-column sub-menu" *ngFor="let link of adminLinks">
              <li class="nav-item"> <a class="nav-link" routerLink="{{link.AC_urlSlug}}">{{link.AC_title}}</a></li> -->
              <!-- <li class="nav-item"> <a class="nav-link" [routerLink]="['/admins/users']">Users</a></li>
                  <li class="nav-item"> <a class="nav-link" [routerLink]="['/admins/role']">Roles</a></li> -->
            <!-- </ul>
          </div>
        </li> -->
        <!-- sidebar changes manage here 22/12/23-->
        <!-- <li *ngIf="userTypeId!=='4'" class="nav-item">
          <a class="nav-link" routerLinkActive="active-menu" routerLink="/dashboard" style="cursor: pointer;">
            <i class="ti-layout-grid2 menu-icon"></i>
            <span class="menu-title">Dashboard</span>
          </a>
        </li> -->

        <!-- <li  class="nav-item" routerLinkActive="active-menu">
          <a class="nav-link" data-toggle="collapse" href="#admin"  aria-expanded="false" aria-controls="admin">
            <i class="icon-head menu-icon"></i>
            <span class="menu-title mt-1">Admin</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="admin">
            <ul class="nav flex-column sub-menu" *ngFor="let link of adminLinks">
              <li class="nav-item"> <a class="nav-link ml-1" routerLink="{{link.AC_urlSlug}}" >{{link.AC_title}}</a></li>
            </ul>
          </div>
        </li>  -->
        
        <!-- <li *ngIf="this.userTypeId==2" class="nav-item" >
          <a class="nav-link"  routerLink="/admins/portal-users" routerLinkActive="active-menu">
            <i class="icon-head menu-icon"></i>
            <span class="menu-title">Portal Users</span>
          </a>
        </li> -->
        
        <li class="nav-item" *ngFor="let menu of accessMenuList">
          <a class="nav-link"  routerLink="{{menu.AM_url}}" routerLinkActive="active-menu">
            <i class="{{menu.AM_icon}} menu-icon"></i>
            <span class="menu-title">{{menu.AM_title}}</span>
          </a>
        </li>

        <!-- <li class="nav-item">
          <a class="nav-link" routerLinkActive="active-menu" routerLink="/actions/notifications" style="cursor: pointer;">
            <i class="fa-solid fa-bell menu-icon"></i>
            <span class="menu-title">Notifications</span>
          </a>
        </li> -->

         <!-- <li class="nav-item">
            <a class="nav-link" routerLinkActive="active-menu" routerLink="/actions/app-users" style="cursor: pointer;">
              <i class="fa-solid fa-users menu-icon"></i>
              <span class="menu-title">App Users</span>
            </a>
          </li> -->
        
          <!-- <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active-menu': isMenuActive('/actions/insights/edit-insights') || isMenuActive('/actions/insights')}" routerLink="/actions/insights" style="cursor: pointer;">
              <i class="icon-microphone  menu-icon"></i>
              <span class="menu-title">Insights</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active-menu': isMenuActive('/actions/grad-scheme') || isMenuActive('/actions/grad-scheme/add-edit-grad-scheme')}" routerLink="/actions/grad-scheme" style="cursor: pointer;">
              <i class="fa-solid fa-users-rectangle menu-icon"></i>
              <span class="menu-title">Grad Schemes</span>
            </a>
          </li>  -->
          <!-- <li class="nav-item">
            <a class="nav-link" routerLinkActive="active-menu" routerLink="/actions/universities" style="cursor: pointer;">
              <i class="fa-solid fa-graduation-cap menu-icon"></i>
              <span class="menu-title">Universities</span>
            </a>
          </li> -->
           <!-- <li class="nav-item">
            <a class="nav-link" routerLinkActive="active-menu" routerLink="/actions/collections" style="cursor: pointer;">
              <i class="fa-solid fa-list-ul menu-icon"></i>
              <span class="menu-title">Collections</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [ngClass]="{'active-menu':isMenuActive('/actions/sectors') || isMenuActive('/actions/sectors/roles') || isMenuActive('/actions/sectors/add-edit-sector')|| isMenuActive('/actions/sectors/roles/add-edit-role')}" routerLink="/actions/sectors" style="cursor: pointer;">
              <i class="fa-solid fa-industry menu-icon"></i>
              <span class="menu-title">Sectors</span>
            </a>
          </li> -->
         
       <!-- <li class="nav-item">
          <a class="nav-link" routerLink="/actions/collections" style="cursor: pointer;">
            <i class="icon-server  menu-icon"></i>
            <span class="menu-title">VoxList</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="collapse" href="#actions" aria-expanded="false" aria-controls="actions">
            <i class="icon-layout menu-icon"></i>
            <span class="menu-title">Actions</span>
            <i class="menu-arrow"></i>
          </a>
          <div class="collapse" id="actions">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/degree']">Career</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/ethnicity']">Ethnicity</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/country']">Country</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/religion']">Religion</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/industry']">Industry</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/regional-accent']">Regional Accent</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/sexual-orientation']">Sexual Orientation</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/pages']">Pages</a></li>
              <li class="nav-item"> <a class="nav-link" [routerLink]="['/actions/quiz']">Quiz</a></li>
            </ul>
          </div>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" (click)="showModal()" style="cursor: pointer;">
            <i class="ti-power-off menu-icon"></i>
            <span class="menu-title">Logout</span>
          </a>
        </li>
    
      
          </ul>
    </nav>
    <!-- partial -->
    <div class="main-panel">
      <ng-content></ng-content>
      <!-- content-wrapper ends -->
      <!-- partial:../../partials/_footer.html -->

      <!-- partial -->
      <!-- <ngx-spinner name="globalSpinner" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" template="<img src='assets/loader/Spinner-1s-200px-2.svg' alt='' 
      >" [fullScreen]="true" type="ball-scale-multiple">
        <p class="mt-5" style="color: white"> Loading... </p>
      </ngx-spinner> -->

      <footer class="footer">
        <div class="d-sm-flex justify-content-center justify-content-sm-between">
          <span class="text-muted text-center text-sm-left d-block d-sm-inline-block">© 2022, Copyright reserved by <a
              href="https://www.gradvisor.org.uk/" target="_blank">Gradvisor LTD</a> </span>
          <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Designed and Developed by <a
              href="https://prysomsystems.com/" target="_blank">Prysom Systems.</a> </span>
        </div>
      </footer>
    </div>

    <!-- main-panel ends -->
  </div>
</div>