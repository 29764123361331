<app-sidebar>
<div class="content-wrapper">  
<div class="row">
    <div class="col-12 grid-margin stretch-card">
      <div class="card">
        <div class="card-header card-title bg-primary rounded-top text-white text-center  mb-0">{{title}}
          User Role</div>
        <div class="card-body">
          <form [formGroup]="addNewUserRoleForm" (ngSubmit)="saveRole()"  class="forms-sample">
            <div class="row">
              <div class="form-group col-lg-6">
                <label class="required-field" for="UT_title">User Role Title</label>
                <input type="text" class="form-control form-control-sm" placeholder="User Role title" formControlName="UT_title" required [readonly]="isReadonly">
                <div class="invalid-feedback">
                  Please enter a role title.
                </div>
              </div>

              <div class="form-group col-lg-6">
                <label class="required-field" for="UT_isActive">Is Active</label>
                <select class="form-control form-control-sm" formControlName="UT_isActive" [attr.disabled]="isReadonly ? '' :null" required>
                  <option selected disabled>Please select yes or no</option>
                  <option value=1>Yes</option>
                  <option value=0>No</option>
                </select>
                <div class="invalid-feedback">
                  Please confirm User role is Active or Not.
                </div>
              </div>

            </div>

            <div class="row"> 
              <div class="form-group col-lg-6">                  
                <label for="UT_description" class="required-field">User Role Description</label>
                <textarea class="form-control form-control-sm" formControlName="UT_description"
                    required [readonly]="isReadonly" rows="4" cols="50" placeholder="User Role description "></textarea>
              <div class="invalid-feedback">
                Please enter a role description.
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="ADM_access">User Role Access</label>
              <div class="tag-input-container">
                <div class="tag-box">
                  <div class="tag" *ngFor="let id of selectedMenuIds; let i = index">
                    {{ getMenuTitle(id) }}
                    <span class="close" (click)="removeMenu(i, 'ADM_access')">x</span>
                  </div>
                </div>
                <select class="form-control tag-input form-control-sm" (change)="addMenu($event)" (keydown)="onBackspaceKey($event, 'ADM_access')" [disabled]="isReadonly" required>
                  <option selected disabled value="">Select a menu</option>
                  <option *ngFor="let menu of MenuList" [value]="menu.AM_title">{{ menu.AM_title }}</option>
                </select>
              </div>
            </div>
            
            
            
            
              
            </div>

            

             
              

            <!-- <div class="form-group">
                <label  for="">Role Access :</label>
                <div class="row my-1">
                    <div class="col-lg-2">
                        <div class="form-check form-check-inline">
                            <input type="checkbox" formControlName="accessInsights" class="form-check-input" id="accessInsights">
                            <label class="form-check-label" for="accessInsights">Insights</label>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-check form-check-inline">
                            <input type="checkbox" formControlName="accessGradSchemes" class="form-check-input" id="accessGradSchemes">
                            <label class="form-check-label" for="accessGradSchemes">Grad Schemes</label>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-check form-check-inline">
                            <input type="checkbox" class="form-check-input" formControlName="accessCollections" id="accessCollections">
                            <label class="form-check-label" for="accessCollections">Collections</label>
                        </div>
                    </div>
                    <div class="col-lg-2">
                        <div class="form-check form-check-inline">
                            <input type="checkbox" class="form-check-input" formControlName="accessSectors" id="accessSectors">
                            <label class="form-check-label" for="accessSectors">Sectors</label>
                        </div>
                    </div>
                </div>
                <div class="invalid-feedback">
                    Please enter a role access.
                </div>
            </div> -->

              <div class="text-center">
              <button *ngIf="!isReadonly" type="submit" class="btn btn-primary mr-2">Save</button>
              <button class="btn btn-light" routerLink="/admins/roles">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</app-sidebar> 