import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataTransferService } from '../shared/services/data-transfer.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  passwordHidden: boolean = true;
  constructor(private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private dataTransferService: DataTransferService,
    private ngxSpinnerService: NgxSpinnerService) { }
  get f() {
    return this.loginForm.controls;
  }
  ngOnInit(): void {
    let token = sessionStorage.getItem('token');
    if (token) {
      this.router.navigate([`/dashboard`]);
    }
    else {
      this.loginForm = this.formBuilder.group({
        UA_email: ['', [Validators.required, Validators.email]],
        UA_password: ['', [Validators.required]]
      });
    }
  }

  get UA_email() {
    return this.loginForm.get('UA_email');
  }

  togglePasswordVisibility() {
    this.passwordHidden = !this.passwordHidden;
}

  onSubmit() {
    try {
      this.submitted = true;
      if (this.loginForm.invalid) {
        this.toastr.info("Please input correct values");
        this.ngxSpinnerService.hide('globalSpinner');
        return;
      }
      this.ngxSpinnerService.show('globalSpinner');
      this.dataTransferService.loginUser(this.loginForm.value).subscribe({next: (res: any) => {
          if (res?.status === 200) {
            this.toastr.success("User successfully logged in");
            this.router.navigate([`/dashboard`]);
            sessionStorage.setItem('token', res.token );
            sessionStorage.setItem('userID', res.userID);
            sessionStorage.setItem('adminModules', JSON.stringify(res.adminModules));
            sessionStorage.setItem('userTypeId', res.userTypeId);
            if(res.userTypeId=="4"){
              this.router.navigate(['/actions/insights'], { queryParams: { userType: res.userTypeId } });
            }else{
            this.router.navigate(['/dashboard'], { queryParams: { userType: res.userTypeId } });
          }
            // sessionStorage.setItem('roleId',res.AD_roleId);
            // console.log('Log in res : ',res);
            // console.log('token', res.token);
            // console.log('userID', res.userID);
            
            
          }
          this.ngxSpinnerService.hide('globalSpinner'); // Move it here
        },
        error: (error) => {
          this.ngxSpinnerService.hide('globalSpinner');
          this.toastr.error("Invalid Credentials");
          this.router.navigate([`/login`]);
          console.log(error);
        }
      });
    } catch (err) {
      console.log(err);
    } 
  }    
  }

// his.dataTransferService.getSoundBite().subscribe({
//   next: (res: any) => {
//     console.log(res);
//     if (res.statusCode == 200) {
//       this.soundList = res.data;
//       this.tempSoundList = [...this.soundList];
//       console.log('Sound', this.soundList);
//       this.ngxSpinnerService.hide('globalSpinner');
//     }
//   },
//   error: (error: any) => {
//     console.log('Error Message', error);
//     this.ngxSpinnerService.hide('globalSpinner');
//   },
// })