import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { Router,ActivatedRoute } from '@angular/router';
import { DataTransferService } from 'src/app/shared/services/data-transfer.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  usersList:any;
  AddUser = false;
  showForm = false;
  roles:any;
  p:any;
  term: string;
  isReadonly = false;
  userForm : FormGroup;
  submitted = false;
  title = 'Add New';
  queryParam :any;
  
  constructor(private formBuilder: FormBuilder,
    private dataTransferService:DataTransferService,
    private toastr:ToastrService,
    private ngxSpinnerService: NgxSpinnerService,
    private domSanitizer: DomSanitizer,
    private router:Router,
    private activeRoute: ActivatedRoute) { }

    get f(){
      return this.userForm.controls;
    }

  ngOnInit(): void {
    this.getAllAdminUsers();
  }

 /*  get f() {
    return this.userForm.controls;
  }
 */
  


  getAllAdminUsers(){
    this.ngxSpinnerService.show('globalSpinner');
    this.dataTransferService.getAllAdminUsers().subscribe((res:any)=>{
      console.log('getAllUsers',res);
      console.log(res);
      if(res.status==200){
        this.usersList = res.data;
        this.ngxSpinnerService.hide('globalSpinner');
      }else{
        this.ngxSpinnerService.hide('globalSpinner');
      }
    },(error:any)=>{
      this.toastr.error("Something went wrong.");
      console.error("error",error);
      this.ngxSpinnerService.hide('globalSpinner');
    })
  }
  getAllRole(){
    this.dataTransferService.getAllRole().subscribe((res:any)=>{
      console.log('getAllRoles',res);
      if(res.status==200){
        this.roles = res.data;
      }
    },(error:any)=>{
      this.ngxSpinnerService.hide('globalSpinner');
      console.log("error",error);
      
    })
  }
  
  addRecord(){
    const queryParams={
      title:'Add New'
    }
    this.router.navigate(['admins/portal-users/add-portal-user'],{queryParams});
  }

  
}
