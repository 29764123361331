<ngx-spinner name="globalSpinner" bdColor="rgba(0, 0, 0, 0.5)" size="small" color="#fff" template="<img src='assets/loader/Spinner-1s-200px-2.svg' alt='' 
      >" [fullScreen]="true" type="ball-scale-multiple">
        <p class="mt-5" style="color: white"> Loading.... </p>
      </ngx-spinner>

      <ngx-spinner name="generateTranscription" bdColor="rgba(0, 0, 0, 0.7)" size="small" color="#fff" template="<img src='assets/loader/Spinner-1s-200px-2.svg' alt='' 
      >" [fullScreen]="true" type="ball-scale-multiple">
        <p class="mt-5 text-center" style="color: white">Generating Transcription.....</p>
      </ngx-spinner>

      <ngx-spinner name="annonymousSpinner" bdColor="rgba(0, 0, 0, 0.7)" size="small" color="#fff" template="<img src='assets/loader/Spinner-1s-200px-2.svg' alt='' 
      >" [fullScreen]="true" type="ball-scale-multiple">
        <p class="mt-5 text-center" style="color: white">Generating Anonymous Insight.....</p>
      </ngx-spinner>

<router-outlet></router-outlet>

